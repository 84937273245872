var api = require("!../../node_modules/.pnpm/style-loader@1.2.1_webpack@4.44.1/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js");
            var content = require("!!../../node_modules/.pnpm/css-loader@4.2.2_webpack@4.44.1/node_modules/css-loader/dist/cjs.js??ref--13-1!../../node_modules/.pnpm/postcss-loader@3.0.0/node_modules/postcss-loader/src/index.js??embedded!../../node_modules/.pnpm/resolve-url-loader@3.1.2/node_modules/resolve-url-loader/index.js??ref--13-3!../../node_modules/.pnpm/sass-loader@10.0.1_sass@1.26.10_webpack@4.44.1/node_modules/sass-loader/dist/cjs.js??ref--13-4!./styles.scss");

            content = content.__esModule ? content.default : content;

            if (typeof content === 'string') {
              content = [[module.id, content, '']];
            }

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



module.exports = content.locals || {};